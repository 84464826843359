<template>
  <div>
    <div class="text-center mb-2 mt-1">
      <el-radio-group v-model="field.is_global_variable" @change="setData">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row :gutter="38">
        <el-col :span="12">
          <div class="form-group">
            <div v-if="errors" class="error-message">
          {{ errorMessage }}
          </div>
            <el-form-item label="Options">
              <el-select
                placeholder="Enter options"
                v-model="field.options"
                :clearable="true"
                size="mini"
                :allow-create="true"
                collapse-tags
                :default-first-option="true"
                :filterable="true"
                multiple
                :no-data-text="''"
                @change="validateOptions"
              >
                <el-option
                  v-for="(opt, index) of field.options"
                  :key="index"
                  :label="opt"
                  :value="opt"
                >
                </el-option>
              </el-select>
              <i class="el-icon-edit ml-05"  @click="openOptions"></i>  
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="mt-2">
           <el-checkbox v-model="field.add_global">Add to Global Variable</el-checkbox>
          </div>
          <div class="mt-1">
            <el-checkbox v-model="field.allow_to_create">Allow User To Create</el-checkbox>
           </div>
        </el-col>
      </el-row>
      <el-row  :gutter="30">
        <el-col :span="6">
          <div class="form-group">
            <el-form-item label="Min Selection">
              <el-input-number
                v-model="field.min_selection"
                :controls="'false'"
              ></el-input-number>
            </el-form-item>
          </div>
        </el-col>
         <el-col :span="6" >
          <div class="form-group">
            <el-form-item label="Max Selection">
              <el-input-number
                v-model="field.max_selection"
                :controls="'false'"
              ></el-input-number>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-attributes :field="field" />
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" />
        </el-col>
      </el-row>
      <!-- <is-field-required :field="field" class="field-required" /> -->
    </el-form>
    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select
                v-model="field.global_variable_id"
                @change="setGlobalVariable"
              >
                <el-option
                  v-for="(globalVariable, index) of allGlobalVariables"
                  :key="index"
                  filterable
                  :label="globalVariable.label"
                  :value="globalVariable._id"
                >
                  <span style="float: left">{{ globalVariable.label }}</span>
                  <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{
                    globalVariable.input_type
                  }}</span> -->
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <is-field-required :field="field" />
    </el-form>
    <div>
      <dialog-component
        :visible="optionsDialogVisible"
          :title="'Edit options'"
          :destroy-on-close="true"
          :containerWidth="'35%'"
          @before-close="optionsDialogVisible=false"
          center>
        <div class="tableScroll">
          <el-table  
           border
          :data="dialogOptions"
          @cell-mouse-enter="editoptionsData" 
          @cell-mouse-leave="resetoptionsData" 
          >
          <el-table-column  label="Options">
            <template slot-scope="scope">
              <div v-if="currentActiveRowIndex == scope.$index">
                <el-input size="mini" v-model="dialogOptions[currentActiveRowIndex]"></el-input>
              </div>
              <div v-else>
                <p style="font-size: 14px" slot="reference">
                {{ scope.row }}
              </p>  
            </div>
            </template>  
          </el-table-column>
          </el-table>
        </div>
          <span slot="footer" class="dialog-footer">
             <el-button style="background-color: var(--primary-color);float:right; color: #ffffff" class="type-2" 
              @click="saveData">Save</el-button>
          </span>
        </dialog-component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import Placeholder from "./Placeholder";

export default {
  name: "templates-formComponents-MultiSelect",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    FieldAttributes: () => import("./FieldAttributes"),
  },
  props: ["field" , "isView"],
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  data() {
    return {
      validations: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
       errors: false,
      errorMessage: "",
      initialValidation:false,
      optionsDialogVisible:false,
      currentActiveRowIndex: -1,
      dialogOptions: [],
    };
  },
  mounted() {
    this.fetchGlobalVaribales();
    this.initialValidation = true;
  },
  methods: {
    openOptions() {
      if (this.isView) {
        return;
      }
      this.optionsDialogVisible = true;
    },
    setData() {
      if (!this.field.is_global_variable) {
        // this.field.options = [];
        this.field.global_variable_id = null;
      }
    },
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_types: ["MULTI_SELECT"],
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
      this.field.min_selection=globalVariable.min_selection;
      this.field.max_selection=globalVariable.max_selection;
    },
     validateOptions() {
      const options = this.field.options;

      // Check if options array is empty
      if (options.length === 0 && this.initialValidation) {
        this.errors = true;
        this.errorMessage = " ";
        return;
      }

      // Check if any option consists only of spaces
      const hasInvalidOptions = options.some((opt) => opt.trim() === "");
      if (hasInvalidOptions) {
        this.errors = true;
        this.errorMessage = "An option should not be only of spaces,so please remove it.";
        return;
      }
      this.errors = false;
      this.errorMessage = "";
    },
    editoptionsData(row) {
      this.currentActiveRowIndex = this.field.options.indexOf(row);
    },
    resetoptionsData() {
      const uniqueOptions =  [...new Set(this.dialogOptions.map(option => option.trim()))];
      if (uniqueOptions.length < this.dialogOptions.length) {
        this.dialogOptions = uniqueOptions;
      }
      this.field.options = this.dialogOptions.slice();
      this.currentActiveRowIndex = -1;
    },
    saveData(){
      this.optionsDialogVisible = false;
    }
   
  },
  watch: {
    optionsDialogVisible(newVal) {
      if (newVal) {
        this.dialogOptions = this.field.options.slice();
      }
    },
  },
};
</script>

<style lang="scss">
.error-message {
  color: red;
}
.tableScroll {
  height: 50vh;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
  width:100%
}
.tableScroll::-webkit-scrollbar {
  width: 0.5em;
}
.tableScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
</style>
